import { SupportedCountry } from '../types';

/*
 * Provides a typesafe way to handle all countries when having to
 * seperate content or business logic by country
 */
export interface ByCountryFn {
    <T>(countryRecords: Record<SupportedCountry, T>): T;
}

export function byCountryFactory(countryCode: SupportedCountry): ByCountryFn {
    return <T>(countryRecords: Record<SupportedCountry, T>) => {
        return countryRecords[countryCode];
    };
}

export function isSupported(country: string): country is SupportedCountry {
    return ['NZ', 'AU'].includes(country);
}
