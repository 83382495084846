import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LocalisationData } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

interface LocaleContextProviderProps {
    data: LocalisationData;
    hydrated: boolean;
    children: React.ReactNode;
}
export type LocalisedDateFn = <
    T extends string | Date | number | null | undefined
>(
    date: T
) => T extends null | undefined ? null : dayjs.Dayjs;

interface ContextData extends LocalisationData {
    localiseDate: LocalisedDateFn;
}

const Context = React.createContext<ContextData | undefined>(undefined);

export const LocalisationContextProvider = ({
    data,
    hydrated,
    children,
}: LocaleContextProviderProps) => {
    let { timezone, locales } = data;
    const { country } = data;

    if (hydrated) {
        const clientContext = Intl.DateTimeFormat().resolvedOptions();
        if (clientContext.timeZone !== timezone) {
            timezone = clientContext.timeZone;
        }
        if (clientContext.locale !== locales[0]) {
            locales = [clientContext.locale];
        }
    }
    const localiseDate = ((date: number | string | Date | null | undefined) => {
        if (!date) {
            return null;
        }
        let retval = dayjs(date);
        try {
            retval = retval.tz(timezone);
        } catch (e) {
            // Ignore
        }
        try {
            const locale = locales[0];
            if (locale) {
                retval = retval.locale(locale);
            }
        } catch (e) {
            // Ignore
        }
        return retval;
    }) as LocalisedDateFn;

    const value = React.useMemo(
        () => ({ country, timezone, locales, localiseDate }),
        [timezone, locales]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * This hook is used to get the current localisation data.
 * On the server render and first client render, the data is taken from the server.
 * On subsequent renders, the data is taken from the client.
 * This hook will trigger a re-render only if the localisation data changes.
 * Localisation data is considered to have changed if the timezone or locale changes.
 * Server detected timezone can be different from client timezone for example whe using
 * a vpn.
 * @param options
 */
const useLocalisation = (): ContextData => {
    const context = React.useContext(Context);
    if (typeof context === 'undefined') {
        throw new Error(
            'Please wrap your application in a LocaleContextProvider.'
        );
    }
    return context;
};

export { useLocalisation };
