import {
    createContext,
    useContext,
    PropsWithChildren,
    useMemo,
    useRef,
} from 'react';
import { ByCountryFn, byCountryFactory } from '../by-country';
import { SupportedCountry } from '../../types';
import { usePrevious } from 'ahooks';

export interface CountryContext {
    code: SupportedCountry;
    /**
     * Handles all possibly values that differ between country to country
     */
    byCountry: ByCountryFn;
}

const CountryContextReact = createContext<CountryContext | undefined>(
    undefined
);

export const useCountryContext = (): CountryContext => {
    const ctx = useContext(CountryContextReact);
    if (typeof ctx === 'undefined') {
        throw new Error('useCountryContext must be used within the provider.');
    }
    return ctx;
};

export const CountryProvider = ({
    code,
    children,
}: PropsWithChildren<{ code: SupportedCountry }>): JSX.Element => {
    const byCountryFn = useRef<ByCountryFn>(byCountryFactory(code));
    const previousCode = usePrevious(code);

    if (previousCode !== code) {
        byCountryFn.current = byCountryFactory(code);
    }

    const contextValue = useMemo(() => {
        return {
            code,
            byCountry: byCountryFn.current,
        };
    }, [code]);

    return (
        <CountryContextReact.Provider value={contextValue}>
            {children}
        </CountryContextReact.Provider>
    );
};
